import React from 'react';
import {Container, Row, Col, Card, CardImg, CardText, CardBody, CardTitle} from 'react-bootstrap';
import LogoHeader from '../components/logoheader/logoHeader'
import '../css/specials.css';
import img1 from '../images/margarito/1.jpg'; 
import img2 from '../images/margarito/2.jpg'; 
import img3 from '../images/margarito/3.jpg'; 
import img4 from '../images/margarito/4.jpg'; 
import img5 from '../images/margarito/5.jpg'; 
import img6 from '../images/margarito/6.jpg'; 

export default function Specials() {
	return (
    	<>
      		<LogoHeader/>
      		<div className="specialsCenterCol">
	      		<Container className="specialsContainer">
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img1}/>
            			</Col>
	     			</Row>
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img2}/>
            			</Col>
	     			</Row>
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img3}/>
            			</Col>
	     			</Row>
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img4}/>
            			</Col>
	     			</Row>
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img5}/>
            			</Col>
	     			</Row>
	     			<Row>
	     				<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              				<img className="showCaseCol" src={img6}/>
            			</Col>
	     			</Row>
	     		</Container>
     		</div>
      	</>
    );
}	